var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("Breadcrumb", {
        staticClass: "breadcrumbAdjust",
        attrs: { pages: _vm.getPages() },
      }),
      _c("div", { staticClass: "grid grid-rows-1 grid-cols-2 mt-8" }, [
        _vm.proposta.ProposalTypeId == 1
          ? _c("p", { staticClass: "title" }, [
              _vm._v(
                "\n      Proposta nº " + _vm._s(_vm.proposta.Number) + "\n    "
              ),
            ])
          : _c("p", { staticClass: "title" }, [
              _vm._v("Endosso nº " + _vm._s(_vm.proposta.Number)),
            ]),
        _c(
          "div",
          { staticClass: "flex justify-end" },
          [
            _vm.podeCancelarProposta
              ? _c(
                  "Button",
                  {
                    class: _vm.podeRealizarEmissao
                      ? "buttonCancel mr-1"
                      : "buttonCancel",
                    on: {
                      click: function ($event) {
                        return _vm.cancelarProposta(_vm.proposta.UniqueId)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "onpoint-x-circle formatPositionIcon",
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.proposta.ProposalTypeId == 1
                            ? "Cancelar proposta"
                            : "Cancelar endosso"
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.podeRealizarEmissao
              ? _c(
                  "Button",
                  {
                    staticClass: "buttonCancel",
                    on: {
                      click: function ($event) {
                        _vm.proposta.ProposalTypeId == 1
                          ? _vm.seguirComEmissao()
                          : _vm.seguirComEndosso()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "onpoint-file formatPositionIcon" }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.proposta.ProposalTypeId == 1
                            ? "Seguir com emissão"
                            : _vm.proposta.Endorsement &&
                              _vm.proposta.Endorsement.EndorsementReasonId == 18
                            ? "Escolher parcelamento"
                            : "Emitir endosso"
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex mt-5 mb-10" },
        [
          _vm.podeGerarMinuta()
            ? _c(
                "Button",
                {
                  staticClass: "mr-2 buttonDownload",
                  on: {
                    click: function ($event) {
                      return _vm.gerarMinutaClick(_vm.proposta.UniqueId)
                    },
                  },
                },
                [_vm._v("\n      Download da minuta\n    ")]
              )
            : _vm._e(),
          _vm.verificaSeBoletoEmitido()
            ? _c(
                "Button",
                {
                  staticClass: "mr-2 buttonDownload",
                  on: {
                    click: function ($event) {
                      _vm.parcelasModal = true
                    },
                  },
                },
                [_vm._v("\n      Download do(s) boleto(s)\n    ")]
              )
            : _vm._e(),
          _vm.verificaSeStatusEhEmitida()
            ? _c(
                "Button",
                {
                  staticClass: "mr-2 buttonDownload",
                  on: {
                    click: function ($event) {
                      return _vm.downloadApoliceClick(_vm.proposta.UniqueId)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.proposta.ProposalTypeId == 2
                          ? "Download do endosso "
                          : " Download da apólice "
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      (_vm.proposta || {}).Timeline
        ? _c(
            "div",
            { staticClass: "steps mt-8 flex flex-row justify-center" },
            _vm._l(
              ["Cotação", "Proposta", "Emissão"],
              function (status, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col justify-center m-5" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "buttonStep",
                          style: _vm.getStepColor(index + 1),
                        },
                        [
                          _c("i", {
                            class: _vm.getTimelineIcon(index + 1),
                            staticStyle: {
                              position: "relative",
                              top: "1px",
                              "font-size": "24px",
                            },
                          }),
                        ]
                      ),
                      _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.proposta.ProposalTypeId == 2 && index == 0
                                ? "Endosso"
                                : status
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                ])
              }
            ),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "grid grid-cols-2 mt-8 gap-5" }, [
        _c(
          "div",
          { staticClass: "card p-8" },
          [
            _c("p", { staticClass: "title" }, [_vm._v("Dados da proposta")]),
            _c("Divider"),
            _c("div", [
              _c("div", [
                _c("div", { staticClass: "topic mt-4" }, [_vm._v("Tomador")]),
                _c("p", { staticClass: "topic-person mt-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.proposta.PolicyHolderBranch
                          ? _vm.proposta.PolicyHolderBranch.Name || {}
                          : _vm.proposta.PolicyHolder.Name || {}
                      ) +
                      "\n          "
                  ),
                ]),
                _c("span", { staticClass: "topic-document mt-4" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("maskCpfCnpj")(
                          _vm.proposta.PolicyHolderBranch
                            ? (_vm.proposta.PolicyHolderBranch || {}).CpfCnpj
                            : (_vm.proposta.PolicyHolder || {}).CpfCnpj
                        )
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "topic mt-4" }, [_vm._v("Segurado")]),
                _c("p", { staticClass: "topic-person mt-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s((_vm.seguradoDaProposta || {}).Name) +
                      "\n          "
                  ),
                ]),
                _c("span", { staticClass: "topic-document" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("maskCpfCnpj")(
                        (_vm.seguradoDaProposta || {}).CpfCnpj
                      )
                    )
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "topic mt-4" }, [
                  _vm._v("Grupo/Modalidade"),
                ]),
                _c("p", { staticClass: "topic-person mt-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(((_vm.proposta || {}).ModalityGroup || {}).Name) +
                      "\n          "
                  ),
                ]),
                _c("span", { staticClass: "topic-document mt-4" }, [
                  _vm._v(_vm._s(((_vm.proposta || {}).Modality || {}).Name)),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "topic mt-4" }, [
                  _vm._v("Importância Segurada"),
                ]),
                _c("p", { staticClass: "topic-person mt-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("maskCurrency")(
                          (_vm.proposta || {}).InsuredAmountValue
                        )
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "topic mt-4" }, [_vm._v("Prêmio")]),
                _c("p", { staticClass: "topic-person mt-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("maskCurrency")(
                          (_vm.proposta || {}).InsurancePremium
                        )
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
            (_vm.proposta || {}).ProposalTypeId == 2
              ? _c(
                  "div",
                  { staticClass: "mt-8" },
                  [
                    _c("p", { staticClass: "title" }, [
                      _vm._v("Dados da Apólice"),
                    ]),
                    _c("Divider"),
                    _c("p", { staticClass: "topic mt-4" }, [_vm._v("Número")]),
                    _c("p", { staticClass: "topic-person mt-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(((_vm.proposta || {}).Policy || {}).Number) +
                          "\n        "
                      ),
                    ]),
                    _c("p", { staticClass: "topic mt-4" }, [
                      _vm._v("Data de Emissão"),
                    ]),
                    _c("p", { staticClass: "topic-person mt-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$utils.dateToDDMMYYYY(
                              ((_vm.proposta || {}).Policy || {}).CreateDate
                            )
                          ) +
                          "\n        "
                      ),
                    ]),
                    _c("p", { staticClass: "topic mt-4" }, [
                      _vm._v("Vigência"),
                    ]),
                    _c("p", { staticClass: "topic-person mt-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            `${_vm.$utils.dateToDDMMYYYY(
                              ((_vm.proposta || {}).Policy || {}).StartDate
                            )} à ${_vm.$utils.dateToDDMMYYYY(
                              ((_vm.proposta || {}).Policy || {}).EndDate
                            )}`
                          ) +
                          "\n        "
                      ),
                    ]),
                    (_vm.proposta || {}).Reference
                      ? _c(
                          "div",
                          { staticClass: "mt-8" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "timeline-new",
                                    params: {
                                      propostaUniqueId: _vm.proposta.Reference,
                                    },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "font-bold p-0",
                                    attrs: {
                                      color: "primary",
                                      type: "flat",
                                      icon: "search",
                                      "icon-after": "",
                                      size: "large",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Ver proposta da apólice\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card p-8" },
          [
            _c("p", { staticClass: "title" }, [
              _vm._v("Histórico da movimentação"),
            ]),
            _c("Divider"),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "historic" }, [
                _c(
                  "div",
                  _vm._l(_vm.timelineHistory || [], function (item) {
                    return _c("div", { key: item.Id, staticClass: "mb-5" }, [
                      _c(
                        "div",
                        { staticClass: "flex card-item items-center" },
                        [
                          _c("div", { staticClass: "mr-5" }, [
                            _c(
                              "button",
                              {
                                staticClass: "buttonStep",
                                style: _vm.getTimelineItemColor(item),
                              },
                              [
                                _c("i", {
                                  class: _vm.getTimelineItemIcon(item),
                                  staticStyle: {
                                    position: "relative",
                                    top: "1px",
                                    "font-size": "24px",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "timeline-info-card" }, [
                            _c("span", { staticClass: "title-info" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.Situation.Name) +
                                  "\n                  "
                              ),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(item.Observation) +
                                    "\n                    "
                                ),
                              ]),
                              _c("span", {
                                staticClass: "text",
                                attrs: {
                                  "v-html": _vm.getTextTimelineHistory(item),
                                },
                              }),
                              _vm.exibeBtnDetalhamento(item)
                                ? _c(
                                    "button",
                                    {
                                      attrs: { type: "filled" },
                                      on: {
                                        click: function ($event) {
                                          _vm.popupDetalhamento = true
                                        },
                                      },
                                    },
                                    [_vm._m(0, true)]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _c("small", { staticClass: "owner" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatActivityDate(
                                      item.CreateByPerson.Name,
                                      item.CreateDate
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._m(1),
            ]),
          ],
          1
        ),
      ]),
      _c("listar-parcelas-popup", {
        attrs: {
          parcelasModal: _vm.parcelasModal,
          propostaUniqueId: _vm.$route.params.propostaUniqueId,
          autoLoad: true,
        },
        on: {
          fecharParcelasModal: function ($event) {
            _vm.parcelasModal = false
          },
        },
      }),
      _c("required-documents", {
        attrs: {
          showModal: _vm.popupDetalhamento,
          detalhamento: _vm.detalhamento,
          showFollowUp: _vm.proposta.StatusId !== 6,
          propostaUniqueId: _vm.$route.params.propostaUniqueId,
        },
        on: {
          closeModal: _vm.closeModalDocuments,
          dismissModal: _vm.dismissModalDocuments,
          updateDocs: _vm.updateDocs,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "onpoint-eye mr-2 my-auto" }),
      _c("span", { staticClass: "text" }, [
        _vm._v(
          "\n                          Visualizar Follow Up/Documentos\n                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex flex-wrap items-center mt-16" }, [
      _c("div", { staticClass: "flex items-center mr-2 mb-1" }, [
        _c("button", {
          staticClass: "buttonAprovado",
          attrs: { disabled: "" },
        }),
        _c("span", { staticClass: "legend" }, [_vm._v("Aprovado")]),
      ]),
      _c("div", { staticClass: "flex items-center mr-2 mb-1" }, [
        _c("button", {
          staticClass: "buttonSeguradora",
          attrs: { disabled: "" },
        }),
        _c("span", { staticClass: "legend" }, [_vm._v("Seguradora")]),
      ]),
      _c("div", { staticClass: "flex items-center mr-2 mb-1" }, [
        _c("button", {
          staticClass: "buttonCorretor",
          attrs: { disabled: "" },
        }),
        _c("span", { staticClass: "legend" }, [_vm._v("Corretor")]),
      ]),
      _c("div", { staticClass: "flex items-center mr-2 mb-1" }, [
        _c("button", {
          staticClass: "buttonRecusado",
          attrs: { disabled: "" },
        }),
        _c("span", { staticClass: "legend" }, [_vm._v("Recusado")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }