<template>
  <div class="container">
    <Breadcrumb :pages="getPages()" class="breadcrumbAdjust" />
    <div class="grid grid-rows-1 grid-cols-2 mt-8">
      <p class="title" v-if="proposta.ProposalTypeId == 1">
        Proposta nº {{ proposta.Number }}
      </p>
      <p class="title" v-else>Endosso nº {{ proposta.Number }}</p>
      <div class="flex justify-end">
        <Button
          :class="podeRealizarEmissao ? 'buttonCancel mr-1' : 'buttonCancel'"
          v-if="podeCancelarProposta"
          @click="cancelarProposta(proposta.UniqueId)"
        >
          <i class="onpoint-x-circle formatPositionIcon"></i>
          {{
            proposta.ProposalTypeId == 1
              ? "Cancelar proposta"
              : "Cancelar endosso"
          }}
        </Button>
        <Button
          class="buttonCancel"
          v-if="podeRealizarEmissao"
          @click="
            proposta.ProposalTypeId == 1
              ? seguirComEmissao()
              : seguirComEndosso()
          "
        >
          <i class="onpoint-file formatPositionIcon"></i>
          {{
            proposta.ProposalTypeId == 1
              ? "Seguir com emissão"
              : proposta.Endorsement &&
                proposta.Endorsement.EndorsementReasonId == 18
              ? "Escolher parcelamento"
              : "Emitir endosso"
          }}
        </Button>
      </div>
    </div>
    <div class="flex mt-5 mb-10">
      <Button
        @click="gerarMinutaClick(proposta.UniqueId)"
        v-if="podeGerarMinuta()"
        class="mr-2 buttonDownload"
      >
        Download da minuta
      </Button>
      <Button
        v-if="verificaSeBoletoEmitido()"
        @click="parcelasModal = true"
        class="mr-2 buttonDownload"
      >
        Download do(s) boleto(s)
      </Button>
      <Button
        v-if="verificaSeStatusEhEmitida()"
        class="mr-2 buttonDownload"
        @click="downloadApoliceClick(proposta.UniqueId)"
      >
        {{
          proposta.ProposalTypeId == 2
            ? "Download do endosso "
            : " Download da apólice "
        }}
      </Button>
    </div>
    <div
      class="steps mt-8 flex flex-row justify-center"
      v-if="(proposta || {}).Timeline"
    >
      <div
        v-for="(status, index) in ['Cotação', 'Proposta', 'Emissão']"
        :key="index"
      >
        <div class="flex flex-col justify-center m-5">
          <button class="buttonStep" :style="getStepColor(index + 1)">
            <i
              :class="getTimelineIcon(index + 1)"
              style="position: relative; top: 1px; font-size: 24px"
            />
          </button>
          <p>
            {{
              proposta.ProposalTypeId == 2 && index == 0 ? "Endosso" : status
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 mt-8 gap-5">
      <div class="card p-8">
        <p class="title">Dados da proposta</p>
        <Divider />
        <div>
          <div>
            <div class="topic mt-4">Tomador</div>
            <p class="topic-person mt-2">
              {{
                proposta.PolicyHolderBranch
                  ? proposta.PolicyHolderBranch.Name || {}
                  : proposta.PolicyHolder.Name || {}
              }}
            </p>
            <span class="topic-document mt-4">
              {{
                proposta.PolicyHolderBranch
                  ? (proposta.PolicyHolderBranch || {}).CpfCnpj
                  : (proposta.PolicyHolder || {}).CpfCnpj | maskCpfCnpj
              }}
            </span>
          </div>
          <div>
            <div class="topic mt-4">Segurado</div>
            <p class="topic-person mt-2">
              {{ (seguradoDaProposta || {}).Name }}
            </p>
            <span class="topic-document">{{
              (seguradoDaProposta || {}).CpfCnpj | maskCpfCnpj
            }}</span>
          </div>
          <div>
            <div class="topic mt-4">Grupo/Modalidade</div>
            <p class="topic-person mt-2">
              {{ ((proposta || {}).ModalityGroup || {}).Name }}
            </p>
            <span class="topic-document mt-4">{{
              ((proposta || {}).Modality || {}).Name
            }}</span>
          </div>
          <div>
            <div class="topic mt-4">Importância Segurada</div>
            <p class="topic-person mt-2">
              {{ (proposta || {}).InsuredAmountValue | maskCurrency }}
            </p>
          </div>
          <div>
            <div class="topic mt-4">Prêmio</div>
            <p class="topic-person mt-2">
              {{ (proposta || {}).InsurancePremium | maskCurrency }}
            </p>
          </div>
        </div>
        <div v-if="(proposta || {}).ProposalTypeId == 2" class="mt-8">
          <p class="title">Dados da Apólice</p>
          <Divider />

          <p class="topic mt-4">Número</p>
          <p class="topic-person mt-2">
            {{ ((proposta || {}).Policy || {}).Number }}
          </p>

          <p class="topic mt-4">Data de Emissão</p>
          <p class="topic-person mt-2">
            {{
              $utils.dateToDDMMYYYY(((proposta || {}).Policy || {}).CreateDate)
            }}
          </p>

          <p class="topic mt-4">Vigência</p>
          <p class="topic-person mt-2">
            {{
              `${$utils.dateToDDMMYYYY(
                ((proposta || {}).Policy || {}).StartDate
              )} à ${$utils.dateToDDMMYYYY(
                ((proposta || {}).Policy || {}).EndDate
              )}`
            }}
          </p>

          <div v-if="(proposta || {}).Reference" class="mt-8">
            <router-link
              :to="{
                name: 'timeline-new',
                params: { propostaUniqueId: proposta.Reference },
              }"
              target="_blank"
              ><vs-button
                class="font-bold p-0"
                color="primary"
                type="flat"
                icon="search"
                icon-after
                size="large"
              >
                Ver proposta da apólice
              </vs-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card p-8">
        <p class="title">Histórico da movimentação</p>
        <Divider />
        <div class="content">
          <div class="historic">
            <div>
              <div
                class="mb-5"
                v-for="item in timelineHistory || []"
                :key="item.Id"
              >
                <div class="flex card-item items-center">
                  <div class="mr-5">
                    <button
                      class="buttonStep"
                      :style="getTimelineItemColor(item)"
                    >
                      <i
                        :class="getTimelineItemIcon(item)"
                        style="position: relative; top: 1px; font-size: 24px"
                      />
                    </button>
                  </div>
                  <div class="timeline-info-card">
                    <span class="title-info">
                      {{ item.Situation.Name }}
                    </span>
                    <div>
                      <span class="text">
                        {{ item.Observation }}
                      </span>
                      <span
                        class="text"
                        :v-html="getTextTimelineHistory(item)"
                      ></span>
                      <button
                        @click="popupDetalhamento = true"
                        v-if="exibeBtnDetalhamento(item)"
                        type="filled"
                      >
                        <div class="flex">
                          <i class="onpoint-eye mr-2 my-auto"></i>
                          <span class="text">
                            Visualizar Follow Up/Documentos
                          </span>
                        </div>
                      </button>
                    </div>
                    <div>
                      <small class="owner">{{
                        formatActivityDate(
                          item.CreateByPerson.Name,
                          item.CreateDate
                        )
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center mt-16">
            <div class="flex items-center mr-2 mb-1">
              <button disabled class="buttonAprovado"></button>
              <span class="legend">Aprovado</span>
            </div>
            <div class="flex items-center mr-2 mb-1">
              <button disabled class="buttonSeguradora"></button
              ><span class="legend">Seguradora</span>
            </div>
            <div class="flex items-center mr-2 mb-1">
              <button disabled class="buttonCorretor"></button
              ><span class="legend">Corretor</span>
            </div>
            <div class="flex items-center mr-2 mb-1">
              <button disabled class="buttonRecusado"></button
              ><span class="legend">Recusado</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <listar-parcelas-popup
      :parcelasModal="parcelasModal"
      :propostaUniqueId="$route.params.propostaUniqueId"
      @fecharParcelasModal="parcelasModal = false"
      :autoLoad="true"
    />

    <required-documents
      :showModal="popupDetalhamento"
      :detalhamento="detalhamento"
      :showFollowUp="proposta.StatusId !== 6"
      :propostaUniqueId="$route.params.propostaUniqueId"
      @closeModal="closeModalDocuments"
      @dismissModal="dismissModalDocuments"
      @updateDocs="updateDocs"
    ></required-documents>
  </div>
</template>

<script>
import Breadcrumb from "/src/components/onpoint-breadcrumb/index.vue";
import Button from "primevue/button";
import Divider from "primevue/divider";
import ListarParcelasPopup from "./components/ListarParcelasPopup.vue";
import RequiredDocuments from "./components/RequiredDocuments.vue";

import * as Enums from "@/utils/enums";
import axiosInstance from "@/axios";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import * as Permissoes from "@/utils/permissions.js";
import mapfreProvider from "@/providers/mapfre-provider.js";

export default {
  components: {
    Breadcrumb,
    Button,
    Divider,
    RequiredDocuments,
    ListarParcelasPopup,
  },
  data() {
    return {
      parcelasModal: false,
      selectedDoc: undefined,
      popupDetalhamento: false,
      modalUpload: false,
      aprovarEndossoModal: false,
      termosAceiteModal: false,
      setStatus: {},
      detalhamento: {},
      uploadDocumentoController: {},
      responsavelDataUpdateEndosso: {},
      timelineHistory: [],
      observacao: "",
      diffEndosso: [],
      tipoCanalCorretora: {},
      permissoesCorretorMapfre: [],
    };
  },
  computed: {
    ...mapGetters("proposta-module", [
      "proposta",
      "tomadorDaProposta",
      "seguradoDaProposta",
    ]),

    podeCancelarProposta() {
      const timeline = (this.proposta || {}).Timeline || {};
      return (
        this.proposta.StatusId == Enums.ProposalStatus.Cotacao ||
        this.proposta.StatusId == Enums.ProposalStatus.EmAnalise ||
        this.proposta.StatusId == Enums.ProposalStatus.AguardandoCorretor ||
        (this.proposta.StatusId == Enums.ProposalStatus.AguardandoEmissao &&
          timeline.LastSituationId == Enums.ProposalTimelineSituation.Emissao &&
          timeline.LastStatusId != Enums.ProposalTimelineStatus.Seguradora)
      );
    },

    podeRealizarEmissao() {
      const timeline = (this.proposta || {}).Timeline || {};
      // if(this.proposta.ProposalTypeId == 1) {
      return (
        timeline.LastSituationId == Enums.ProposalTimelineSituation.Emissao &&
        timeline.LastStatusId == Enums.ProposalTimelineStatus.Corretor &&
        this.proposta.StatusId != Enums.ProposalStatus.AguardandoPagamento &&
        this.proposta.StatusId != Enums.ProposalStatus.Cancelada &&
        this.proposta.StatusId != Enums.ProposalStatus.Recusada
      );
    },

    exibeBtnDetalhamento() {
      return (itemHistory) =>
        this.timelineHistory[0] == itemHistory &&
        itemHistory.StatusId == Enums.ProposalTimelineStatus.Corretor &&
        itemHistory.SituationId != Enums.ProposalTimelineSituation.Emissao;
    },

    podeExibirTagCanalCorretorMapfre() {
      return this.permissoesCorretorMapfre.includes("ExibirCanal");
    },
  },
  watch: {
    "$route.params.propostaUniqueId": function (newId, oldId) {
      this.loadProposta(newId);
    },
  },
  async mounted() {
    await this.loadProposta(this.$route.params.propostaUniqueId);
    await this.visualizarDetalhamento();
    await this.pegarPermissoesMapfre();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "timeline-new",
      url: window.location.href,
    });
  },
  methods: {
    ...mapActions("proposta-module", ["loadPropostaByUniqueId"]),
    ...mapActions("propostas-module", ["gerarMinuta", "downloadBoleto"]),
    ...mapActions("apolice-module", ["downloadApolice"]),
    ...mapActions("endosso-module", ["getCompareChanges"]),

    closeModalDocuments() {
      this.loadProposta(this.$route.params.propostaUniqueId);
      this.popupDetalhamento = false;
    },
    dismissModalDocuments() {
      this.popupDetalhamento = false;
    },
    getTimelineItemColor(item) {
      if (item.StatusId == Enums.ProposalTimelineStatus.Aprovado)
        return {
          color: "#147638",
          "border-color": "#147638",
          "background-color": "#D3F3DF",
        };
      else if (item.StatusId == Enums.ProposalTimelineStatus.Seguradora)
        return {
          color: "#4868E0",
          "border-color": "#4ed17e",
          "background-color": "#d1d9f7",
          border: "1px solid",
        };
      else if (item.StatusId == Enums.ProposalTimelineStatus.Corretor)
        return {
          color: "#f7b13c",
          "border-color": "#f7b13c",
          "background-color": "#fdecce",
          border: "1px solid",
        };
      else if (item.StatusId == Enums.ProposalTimelineStatus.Recusado)
        return {
          color: "#f6657e",
          "border-color": "#f6657e",
          "background-color": "#fdd9df",
          border: "1px solid",
        };
      else if (item.StatusId == Enums.ProposalTimelineStatus.Cancelado)
        return {
          color: "#f6657e",
          "border-color": "#f6657e",
          "background-color": "#fdd9df",
          border: "1px solid",
        };
      else
        return {
          color: "black",
          "border-color": "black",
          "background-color": "black",
          border: "1px solid",
        };
    },
    getTimelineItemIcon(item) {
      if (item.StatusId == Enums.ProposalTimelineStatus.Aprovado)
        return "onpoint-check";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Seguradora)
        return "onpoint-warning";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Corretor)
        return "onpoint-warning";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Recusado)
        return "onpoint-x";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Cancelado)
        return "onpoint-x";
      else return "onpoint-check";
    },
    getTextTimelineHistory(item) {
      let text = "";

      if (this.isAguardandoPagamentoTimeline(item)) {
        text = "Aguardando pagamento";
      } else if (item.Observation) {
        if (
          this.proposta.StatusId != Enums.ProposalStatus.AguardandoPagamento
        ) {
          if (item.StatusId == Enums.ProposalTimelineStatus.Corretor) {
            text += "Solicitado: ";
          } else if (item.StatusId == Enums.ProposalTimelineStatus.Seguradora) {
            text += "Enviado: ";
          }
        }
        text += `${item.Observation.replaceAll("/n", " / ")}`;
      } else {
        text += `Etapa de ${item.Situation.Name} `;
        if (item.StatusId == Enums.ProposalTimelineStatus.Aprovado) {
          text += "executada";
        } else {
          text += "iniciada";
        }
      }

      return text;
    },

    async loadGetCompareChanges() {
      if (
        this.proposta.ProposalTypeId == 2 &&
        (this.proposta.Endorsement || {}).EndorsementTypeId == 2
      )
        return await this.getCompareChanges({
          uniqueId: this.$route.params.propostaUniqueId,
        }).then((result) => {
          this.responsavelDataUpdateEndosso = {
            UserName: result.UserOwner.Name,
            ModifiedAt: result.ModifiedAt,
          };

          ((result || {}).ChangeTypes || []).forEach((item) => {
            item.ChangesValues.forEach((element) => {
              this.diffEndosso.push({
                current: element.Current ? element.Current : "--",
                origin: element.Previous ? element.Previous : "--",
                label: element.Name,
                key: element.Name + element.Current,
              });
            });
          });
        });
    },

    getStepColor(situationGroup) {
      const currentSituationGroup =
        this.proposta.Timeline.Situation.ProposalTimelineSituationGroupId;
      const currentStatus = this.proposta.Timeline.LastStatusId;

      if (situationGroup > currentSituationGroup) {
        return {
          color: "#4B5563",
          "border-color": "#4B5563",
          "background-color": "#E5E7EB",
        };
      } else if (
        situationGroup < currentSituationGroup ||
        currentStatus == Enums.ProposalTimelineStatus.Aprovado
      ) {
        return {
          color: "#147638",
          "border-color": "#147638",
          "background-color": "#D3F3DF",
        };
      } else if (
        currentStatus == Enums.ProposalTimelineStatus.Recusado ||
        currentStatus == Enums.ProposalTimelineStatus.Cancelado
      )
        return {
          color: "#F6657E",
          "border-color": "#F6657E",
          "background-color": "#FDD9DF",
        };
      else
        return {
          color: "#F7B13C",
          "border-color": "#F7B13C",
          "background-color": "#FDECCE",
        };
    },

    getTimelineIcon(situationGroup) {
      const currentSituationGroup =
        this.proposta.Timeline.Situation.ProposalTimelineSituationGroupId;
      const currentStatus = this.proposta.Timeline.LastStatusId;

      if (situationGroup > currentSituationGroup) {
        return "onpoint-clock";
      } else if (
        situationGroup < currentSituationGroup ||
        currentStatus == Enums.ProposalTimelineStatus.Aprovado
      ) {
        return "onpoint-check";
      } else if (
        currentStatus == Enums.ProposalTimelineStatus.Recusado ||
        currentStatus == Enums.ProposalTimelineStatus.Cancelado
      )
        return "onpoint-x";
      else return "onpoint-warning";
    },

    isAguardandoPagamentoTimeline(itemHistory) {
      return (
        this.proposta.StatusId == Enums.ProposalStatus.AguardandoPagamento &&
        itemHistory.SituationId == Enums.ProposalTimelineSituation.Emissao &&
        itemHistory.StatusId == Enums.ProposalTimelineStatus.Aprovado
      );
    },

    verificaSeBoletoEmitido() {
      return this.proposta.ProposalInstallmentArchiveBilletId != null;
    },
    // verificar onde colocar o botão de download da apolice
    verificaSeStatusEhEmitida() {
      return this.proposta.StatusId == Enums.ProposalStatus.Emitida;
    },

    podeGerarMinuta() {
      return (
        this.proposta.StatusId != Enums.ProposalStatus.Cotacao &&
        this.proposta.StatusId != Enums.ProposalStatus.Emitida &&
        this.proposta.StatusId != Enums.ProposalStatus.Recusada &&
        this.proposta.StatusId != Enums.ProposalStatus.Cancelada &&
        this.proposta.StatusId != Enums.ProposalStatus.EmAnalise &&
        this.proposta.StatusId != Enums.ProposalStatus.AguardandoCorretor &&
        (this.proposta.Endorsement || {}).EndorsementTypeId !== 1
      );
    },

    visualizarDetalhamento() {
      return axiosInstance
        .get(
          `api/Proposal/GetProposalTimelineInfo?ProposalUniqueId=${this.$route.params.propostaUniqueId}`
        )
        .then((response) => {
          this.detalhamento = response.data.Response;
        })
        .catch((error) => {
          this.$onpoint.errorModal(error.response.Message);
        });
    },

    updateDocs(docs) {
      this.detalhamento.RequestDocuments = docs;
    },

    async loadProposta(propostaUniqueId) {
      await this.$onpoint.loadingModal(
        "Carregando informações da proposta",
        async () => {
          return await this.loadPropostaByUniqueId(propostaUniqueId)
            .then(() => {
              this.loadTimelineHistory(
                this.$route.params.propostaUniqueId
              ).then(() => this.loadGetCompareChanges());
            })
            .catch((error) => {
              this.$onpoint.errorModal(error.response.Message);
            });
        }
      );
    },

    cancelarProposta(propostaUniqueId) {
      this.$onpoint.loadingModal("Estamos cancelando a proposta", () => {
        return axiosInstance
          .post(
            `api/Proposal/CancelProposal?ProposalUniqueId=${propostaUniqueId}`
          )
          .then(() => {
            this.$router.push({ name: "propostas" });
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    loadTimelineHistory(propostaUniqueId) {
      return axiosInstance
        .get(
          `api/Proposal/GetProposalTimelineHistory?ProposalUniqueId=${propostaUniqueId}`
        )
        .then((response) => {
          this.timelineHistory = response.data.Response;
        })
        .catch((error) => {
          this.$onpoint.errorModal(error.response.Message);
        });
    },

    formatActivityDate(createdBy, creationDate) {
      const date = new Date(creationDate);

      return `Executado por ${createdBy} em ${moment(date).format(
        "DD/MM/YYYY"
      )} às ${moment(date).format("HH:mm")}`;
    },

    seguirComEmissao() {
      this.$router.push({
        name: "editar-proposta",
        params: { propostaUniqueId: this.$route.params.propostaUniqueId },
      });
    },

    async gerarMinutaClick(propostaUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.gerarMinuta(propostaUniqueId)
          .then((urlToDownload) => {
            window.open(urlToDownload, "_self");
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    downloadApoliceClick(propostaUniqueId) {
      let payload = {
        propostaUniqueId: propostaUniqueId,
        endorsement: true,
      };

      this.$onpoint.loading(() => {
        return this.downloadApolice(payload)
          .then((urlToDownload) => {
            window.open(urlToDownload, "_self");
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async pegarPermissoesMapfre() {
      this.permissoesCorretorMapfre = [];
      this.tipoCanalCorretora = {};
      try {
        this.permissoesCorretorMapfre =
          await mapfreProvider.obterPermissoesModulo(
            Permissoes.Modulo.PROPOSTA
          );
        this.tipoCanalCorretora =
          await mapfreProvider.buscarTipoCanalPorCorretora();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async seguirComEndosso() {
      await this.$onpoint.loading(async () => {
        try {
          await axiosInstance
            .post(
              `/api/Endorsement/CanEndorsementEmission?proposalId=${this.proposta.Id}`
            )
            .then((data) => {
              !!data.data.Response.PodeEmitir
                ? this.$router.push({
                    name: "endosso-emissao",
                    params: {
                      uniqueId: this.proposta.UniqueId,
                    },
                  })
                : this.$onpoint.errorModal(data.data.Response.Mensagem);
            });
        } catch (error) {
          this.$onpoint.errorModal();
        }
      });
    },
    getPages() {
      return [
        { label: "Propostas", to: "/propostas" },
        { label: `Proposta nº ${this.proposta.Number}` },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.formatPositionIcon {
  position: relative;
  top: 2px;
}
.title {
  font-size: 24px !important;
  line-height: 30px;
  font-weight: 700;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: #4b5563;
}
.buttonCancel {
  border-radius: 8px !important;
  padding: 8px 16px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: #f43f5e;
}
.buttonCancel:enabled:hover {
  cursor: pointer;
  background: rgba(#f43f5e, 0.8) !important;
  border-color: rgba(#f43f5e, 0.8) !important;
}
.breadcrumbAdjust {
  position: relative;
  right: 10px;
}

.steps {
  min-height: 100px;
  background: white;
  border-radius: 8px;
}
.container {
  width: 80vw;
}
.buttonStep {
  width: 40px;
  height: 40px;
  border: 1px solid;
}
.card {
  background: white;
  border-radius: 8px;
  min-height: 588px;
  .title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .topic {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b5563;
  }
  .topic-person {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .topic-document {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .content {
    height: 100%;
  }
}
.historic {
  height: 85%;
  .card-item {
    background: #f3f4f6;
    border-radius: 16px;
    padding: 12px;
  }
}

.buttonAprovado {
  width: 24px;
  height: 24px;
  border-color: #4ed17e;
  background-color: #d3f3df;
  border: 1px solid;
}
.buttonSeguradora {
  width: 24px;
  height: 24px;
  border-color: #4ed17e;
  background-color: #d1d9f7;
  border: 1px solid;
}
.buttonCorretor {
  width: 24px;
  height: 24px;
  border-color: #f7b13c;
  background-color: #fdecce;
  border: 1px solid;
}
.buttonRecusado {
  width: 24px;
  height: 24px;
  border-color: #f6657e;
  background-color: #fdd9df;
  border: 1px solid;
}
.legend {
  margin-left: 5px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.timeline-info-card {
  .title-info {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b5563;
  }
  .text {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .owner {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
.buttonDownload {
  border-radius: 8px !important;
  padding: 8px 16px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
</style>